// src/utils/pdfGenerator.js

import jsPDF from "jspdf";
import JSZip from "jszip";
import { myLogo } from "print/data.js";
import { sensDeroul } from "@/utils/data.js";
import { datefr } from "@/utils/functions.js";
export function generatePdf (api, fileUrl, product, callback) {
  let xhr = new XMLHttpRequest();

  xhr.open("GET", api + fileUrl, true);
  xhr.responseType = "blob";
  xhr.onprogress =  ()=> {
    console.log("Downloading...");
  };

  xhr.onload =  () =>{
    if (xhr.status === 200) {
      var zip = new JSZip();

      zip.loadAsync(xhr.response).then( (contents)=> {
        Object.keys(contents.files).forEach( (filename)=> {
          zip.file(filename)
            .async("blob")
            .then( (content)=> {
              let reader = new FileReader();
              reader.readAsDataURL(content); // Convert the blob to a base64 data URL
              reader.onloadend =  () =>{
                let base64data = reader.result;
                // Create an A4 document
                var doc = new jsPDF({
                  orientation: "portrait",
                  unit: "mm",
                  format: "a4",
                });

                // A4 dimensions
                const pageWidth = 210;
                const pageHeight = 297;

                // Reserve space for the header and footer
                const headerHeight = 37; // Header space
                const footerHeight = 65; // Footer space
                const availableHeight = pageHeight - headerHeight - footerHeight;

                // Add header content (if any)
                doc.addImage(
                  myLogo,
                  "png",
                  4, 9, 60, 25

                );
                doc.setFontSize(10);

                let doc_head = [];
                doc_head.push({
                  lib1: {
                    content: "CLIENT :",
                    styles: { fontStyle: "normal", fontSize: 10 },
                  },
                  val1: {
                    content: product.client_name,
                    styles: { fontStyle: "normal", fontSize: 10 },
                    colSpan: 3
                  }

                });
                doc_head.push({
                  lib1: {
                    content: "Designation :",
                    styles: { fontStyle: "normal", fontSize: 10 },
                  },
                  val1: {
                    content: product.label,
                    styles: { fontStyle: "normal", fontSize: 10 },
                    colSpan: 3
                  }
                });



                doc_head.push({
                  lib1: {
                    content: "Code Produit :",
                    styles: { fontStyle: "normal", fontSize: 10 },
                  },
                  val1: product.code,
                  lib2: {
                    content: "Réf Client :",
                    styles: { fontStyle: "normal", fontSize: 10 },
                  },
                  val2: product.ref,
                });
                doc_head.push({
                  lib1: {
                    content: "BAG établit le :",
                    styles: { fontStyle: "normal", fontSize: 10 },
                  },
                  val1: datefr(JSON.parse(product.bag).uplode_date, false, "numeric"),
                  lib2: {
                    content: "Version :",
                    styles: { fontStyle: "normal", fontSize: 10, },
                  },
                  val2: 'V1',
                });

                let columns = [];
                columns.push({ dataKey: "lib1" });
                columns.push({ dataKey: "val1" });
                columns.push({ dataKey: "lib2" });
                columns.push({ dataKey: "val2" });

                // Table entete --------- //
                doc.autoTable({
                  margin: { left: pageWidth / 2 - 32, top: 10, },
                  body: doc_head,
                  columnStyles: {
                    0: { halign: "right", cellWidth: 26 },
                    1: { halign: "left", cellWidth: 30, fontStyle: 'bold' },
                    2: { halign: "right", cellWidth: 25 },
                    3: { halign: "center", cellWidth: 42, },
                  },
                  columns: columns,

                  theme: "grid",
                  styles: {
                    valign: "top",
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 1,
                    lineColor: [169, 169, 169], // Light grey lines
                    lineWidth: 0.1,

                  },
                  tableWidth: 'wrap',
                });
                doc.setDrawColor(169, 169, 169);
                doc.setLineWidth(0.1);
                doc.line(9, headerHeight + 5, pageWidth - 14, headerHeight + 5,);

                // Create a new image element to get the image dimensions
                let img = new Image();
                img.src = base64data;
                img.onload =  () => {
                  // Original image dimensions in mm (assuming 72 DPI)
                  const imgWidth = img.width * 0.3528;
                  const imgHeight = img.height * 0.3528;

                  // Calculate the aspect ratio
                  const imgAspectRatio = imgWidth / imgHeight;

                  // Determine the new dimensions based on the available space while preserving aspect ratio
                  let newWidth, newHeight;
                  if (imgAspectRatio > 1) {
                    // Landscape orientation of the image
                    newWidth = pageWidth * 0.85; // Max width is 90% of page width
                    newHeight = newWidth / imgAspectRatio;
                    //scale = newWidth / imgWidth;
                  } else {
                    // Portrait orientation of the image
                    newHeight = availableHeight * 0.85; // Max height is 90% of available height
                    newWidth = newHeight * imgAspectRatio;
                    //scale = newHeight / imgHeight;
                  }

                  // Calculate the position to center the image between the header and footer
                  const xOffset = (pageWidth - newWidth) / 2;
                  const yOffset = headerHeight + (availableHeight - newHeight) / 2;

                  // Add the image to the PDF
                  doc.addImage(
                    base64data,
                    "JPEG",
                    xOffset,
                    yOffset,
                    newWidth,
                    newHeight
                  );


                             doc.line(9, pageHeight - footerHeight, pageWidth - 14, pageHeight - footerHeight);
                  doc_head = [];

                  columns = [];
                  columns.push({ dataKey: "lib1" });
                  columns.push({ dataKey: "val1" });
                  doc.addImage(
                    sensDeroul,
                    "png",
                    8, pageHeight - footerHeight + 3, 88, 22

                  );

                  let support = ""
                  let mandrin = ""
                  let bobine = ""
                  let i = product.attributs.findIndex(elm => elm.id == 9)
                  if (i >= 0) support = product.attributs[i].value
                  doc_head.push({
                    lib1: {
                      content: "Support : ",
                      styles: { fontStyle: "normal", fontSize: 10 },
                    },
                    val1: {
                      content: support,
                      styles: { fontStyle: "normal", fontSize: 10 },

                    }
                  });

                  i = product.attributs.findIndex(elm => elm.id == 43)
                  if (i >= 0) mandrin = product.attributs[i].value
                  doc_head.push({
                    lib1: {
                      content: "Dia Mandrin (mm) ",
                      styles: { fontStyle: "normal", fontSize: 10 },
                    },
                    val1: {
                      content: mandrin + " ",
                      styles: { fontStyle: "normal", fontSize: 10 },

                    }
                  });
                  i = product.attributs.findIndex(elm => elm.id == 44)
                  if (i >= 0) bobine = product.attributs[i].value
                  doc_head.push({
                    lib1: {
                      content: "Dia Bobine (mm) ",
                      styles: { fontStyle: "normal", fontSize: 10 },
                    },
                    val1: {
                      content: bobine,
                      styles: { fontStyle: "normal", fontSize: 10 },

                    }
                  });
                  doc.autoTable({
                    margin: { left: 9, },
                    startY: pageHeight - footerHeight + 30,
                    body: doc_head,
                    columnStyles: {
                      0: { halign: "left", cellWidth: 40 },
                      1: { halign: "left", cellWidth: 46, },
                    },

                    columns: columns,
                    theme: "grid",
                    styles: {
                      valign: "top",
                      fillColor: [255, 255, 255],
                      textColor: [0, 0, 0],
                      cellPadding: 1,
                    },
                    tableWidth: 'wrap',
                  });
                  doc.setFontSize(8);
                  var splitTitle = doc.splitTextToSize(`Je Soussigné déclare avoir verifé toutes les données du present BAG ( que je certifie conforme à notre cahier des charges ) et dégage par consequent Concept Emballage de toute responsabilité quant à d'eventuelles erreurs sur le present document`, 86);
                  doc.text(
                    splitTitle,
                    109,
                    pageHeight - footerHeight + 7,
                    { align: "left" },
                  );
                  doc.setFontSize(10);
                  doc.text(
                    'Date : ...........................',
                    109,
                    pageHeight - footerHeight + 26,
                    { align: "left" },
                  );
                  doc.text(
                    'Signature et Cachet',
                    157,
                    pageHeight - footerHeight + 26,
                    { align: "left" },
                  );
                  doc.text(
                    'Nom : ...........................',
                    109,
                    pageHeight - footerHeight + 36,
                    { align: "left" },
                  );
                  doc.text(
                    'Fonction : .....................',
                    109,
                    pageHeight - footerHeight + 46,
                    { align: "left" },
                  );
             
                  // Use callback to handle the generated PDF
                  if (callback) {
                    callback(doc);
                  }
                };
              };
            })
            .catch( (error)=> {
              console.error("Error processing the file in JSZip:", error);
            });
        });
      }).catch( (error)=> {
        console.error("Error loading the ZIP file:", error);

      });
    } else {
      console.error("Failed to download the file. Status:", xhr.status);

    }
  };
  xhr.onerror =  () =>{
    console.error("An error occurred during the download process.");
  };

  xhr.send();
}
