<template>
  <div>
    <v-dialog
      v-model="showForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      @keydown.esc="close"
    >
      <v-form ref="form2" v-model="valid" lazy-validation>
        <v-card flat>
          <v-card-title>
            {{ formTitle }}
            <v-spacer> </v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01060') ||
                      $store.state.isadmin)
                  "
                  @click.stop="hist_click"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="editedItem.id > 0"
                  @click.stop="drawer2 = !drawer2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-notebook-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Suivi Info produit</span>
            </v-tooltip>
            <v-btn
              color="blue darken-1"
              text
              @click="validate"
              v-if="
                valider &&
                (editedItem.statut_id == '1' ||
                  editedItem.statut_id == '11' ||
                  editedItem.statut_id == '12') &&
                editedItem.id > 0
              "
              :disabled="change > minchange"
            >
              Valider
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="change == minchange"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              {{ change == minchange ? "Fermer" : "Annuler" }}
            </v-btn>
          </v-card-title>

          <v-card-subtitle>
            <h4>
              Code : {{ editedItem.code }} - Créé le
              {{ datefr(editedItem.created_at) }} Par {{ editedItem.contact }}
            </h4>
          </v-card-subtitle>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>
          <v-divider></v-divider>
          <v-card-text>
            <v-tabs
              v-model="tab1"
              vertical
              class="my-tabs"
              @change="tab_change"
            >
              <v-tab key="1" href="#1"> Informations Generales </v-tab>
              <v-tab
                key="2"
                href="#2"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.tosell == 1 &&
                  ($store.state.auth.includes('01027') ||
                    $store.state.isadmin) &&
                  editedItem.statut_id > '1'
                "
              >
                Prix de vente
              </v-tab>
              <v-tab
                key="3"
                v-if="
                  editedItem.service == '0' &&
                  editedItem.id != -1 &&
                  editedItem.statut_id > '1'
                "
                href="#3"
              >
                Stock
              </v-tab>
              <v-tab
                key="4"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.statut_id > '1' &&
                  editedItem.tobuy == 1
                "
                href="#4"
              >
                Fournisseurs
              </v-tab>

              <v-tab key="5" v-if="editedItem.id != -1" href="#5">
                {{
                  editedItem.service == "0" &&
                  editedItem.id != -1 &&
                  editedItem.tomanufacture == 1 &&
                  prd_type == "04"
                    ? "Attributs/RECETTE"
                    : "Attributs"
                }}
              </v-tab>
              <v-tab
                key="6"
                v-if="
                  editedItem.id != -1 &&
                  editedItem.statut_id > '1' &&
                  editedItem.tomanufacture == 1 &&
                  editedItem.service == '0' &&
                  editedItem.stock_cmpt == 0 &&
                  pc &&
                  prd_type == '04'
                "
                href="#6"
              >
                Produits Composés
              </v-tab>
              <v-tab
                key="7"
                v-if="editedItem.id != -1 && editedItem.statut_id > '1'"
                href="#7"
              >
                Documents attachés
              </v-tab>

              <v-tabs-items v-model="currentTab">
                <v-tab-item :value="'1'">
                  <v-card flat>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" lg="8" md="8" sm="8">
                          <v-card flat class="pa-3">
                            <v-row dense>
                              <!-- Ref Externe -->
                              <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.ref"
                                  label="Réf."
                                  :readonly="!modify"
                                  :rules="[
                                    (v) => !v || ref_code || 'Ref. existe !!',
                                  ]"
                                  :loading="libv"
                                  @input="lib_change"
                                ></v-text-field>
                              </v-col>
                              <!-- Libelle -->
                              <v-col cols="12" sm="10" md="10">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.label"
                                  label="Libelle"
                                  :rules="[
                                    (v) => !!v || 'Libelle obligatoire',
                                    (v) => lib || 'Libelle existe !!',
                                  ]"
                                  :loading="libv"
                                  :readonly="
                                    editedItem.statut_id > '1' ||
                                    !modify ||
                                    editedItem.cmddetailcount > 0 ||
                                    editedItem.fk_template != null
                                  "
                                  @input="lib_change"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <!-- Nature produit -->
                            <v-row dense>
                              <v-col cols="12" sm="2" md="2">
                                <v-select
                                  :items="[
                                    { id: '0', label: 'Produit ' },
                                    { id: '1', label: 'Service' },
                                  ]"
                                  v-model="editedItem.service"
                                  item-text="label"
                                  item-value="id"
                                  label="Nature du produit"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Nature du produit obligatoire',
                                  ]"
                                  outlined
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' ||
                                    !modify ||
                                    editedItem.cmddetailcount != 0 ||
                                    editedItem.fk_template != null
                                  "
                                  :disabled="prd_type == '04'"
                                  @change="service_change"
                                ></v-select>
                              </v-col>
                              <!-- Type Produit -->
                              <v-col cols="12" sm="3" md="3">
                                <v-select
                                  :items="products_types"
                                  v-model="editedItem.fk_product_type"
                                  item-text="label"
                                  item-value="id"
                                  label="Type de Produit"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      products_types.length == 0 ||
                                      'Type de Produit obligatoire',
                                  ]"
                                  outlined
                                  dense
                                  :readonly="
                                    (editedItem.id > 0 &&
                                      editedItem.statut_id > '1') ||
                                    !modify ||
                                    editedItem.cmddetailcount != 0
                                  "
                                ></v-select>
                              </v-col>
                              <!-- Unite -->
                              <v-col cols="12" sm="2" md="2">
                                <v-autocomplete
                                  :items="
                                    units.filter((elm) => elm.uom_type == 'm')
                                  "
                                  v-model="editedItem.fk_unit"
                                  item-text="name"
                                  item-value="id"
                                  label="Unité de Mesure"
                                  :rules="[
                                    (v) => !!v || 'Unité de Mesure obligatoire',
                                  ]"
                                  outlined
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' ||
                                    !modify ||
                                    editedItem.cmddetailcount != 0
                                  "
                                  @change="unit_change"
                                >
                                  <template v-slot:item="{ item }">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{
                                      item.name +
                                      " (" +
                                      item.category_name +
                                      ")"
                                    }}
                                  </template>
                                </v-autocomplete>
                              </v-col>

                              <!-- Precision -->
                              <v-col cols="12" sm="1" md="1">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  type="number"
                                  v-model.number="editedItem.decimal"
                                  label="Precision"
                                  :rules="[
                                    (v) => v != null || 'Precision obligatoire',
                                  ]"
                                  :readonly="
                                    !modify || editedItem.fk_template != null
                                  "
                                  hide-spin-buttons
                                ></v-text-field>
                              </v-col>

                              <!-- Entrepot -->

                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                lg="3"
                                v-if="
                                  editedItem.service == '0' &&
                                  prd_type != '04' &&
                                  $store.state.structures.filter(
                                    (elm) => elm.type_id == 2
                                  ).length >= 1
                                "
                              >
                                <v-autocomplete
                                  outlined
                                  :items="depots"
                                  v-model="editedItem.depot_id"
                                  item-text="label"
                                  item-value="id"
                                  label="Entrepôt par défaut"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Entrepôt par défaut obligatoire',
                                  ]"
                                  :readonly="!modify"
                                  dense
                                ></v-autocomplete>
                              </v-col>
                              <!-- en achat-->
                              <v-col
                                cols="12"
                                sm="1"
                                md="2"
                                v-if="prd_type != '02'"
                              >
                                <v-checkbox
                                  v-model="editedItem.tobuy"
                                  label="En achat"
                                  :readonly="!modify"
                                  class="ma-2"
                                  :disabled="prd_type == '02'"
                                ></v-checkbox>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="1"
                                md="2"
                                v-if="prd_type != '03'"
                              >
                                <v-checkbox
                                  v-model="editedItem.tosell"
                                  label="En vente"
                                  :readonly="!modify"
                                  class="ma-2"
                                  :disabled="prd_type == '03'"
                                ></v-checkbox>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="1"
                                md="2"
                                v-if="
                                  editedItem.service == '0' &&
                                  (prd_type == '04' || prd_type == '03')
                                "
                              >
                                <v-checkbox
                                  v-model="editedItem.tomanufacture"
                                  label="En Fabrication"
                                  :readonly="!modify"
                                  class="ma-2"
                                  :disabled="editedItem.mvmfabcount > 0"
                                ></v-checkbox>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="1"
                                md="2"
                                v-if="editedItem.service == '0' && moule"
                              >
                                <v-checkbox
                                  v-model="editedItem.is_moule"
                                  label="Moule"
                                  :readonly="
                                    !modify || editedItem.fk_template != null
                                  "
                                  class="ma-2"
                                  :disabled="editedItem.statut_id > '1'"
                                ></v-checkbox>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                v-if="$store.state.isadmin"
                              >
                                <v-autocomplete
                                  outlined
                                  :items="status_list"
                                  v-model="editedItem.statut_id"
                                  item-text="label"
                                  item-value="id"
                                  label="Etat du produit"
                                  :readonly="!modify"
                                  dense
                                ></v-autocomplete>
                              </v-col>
                              <!-- Code compta -->
                              <v-col cols="12" sm="3" md="3">
                                <v-autocomplete
                                  :items="plancomptable"
                                  :item-text="
                                    (item) =>
                                      item.account_number + ' - ' + item.label
                                  "
                                  item-value="id"
                                  outlined
                                  dense
                                  v-model="editedItem.code_cmpt"
                                  label="Code Comptable"
                                  :readonly="
                                    (item.code_cmpt &&
                                      editedItem.statut_id > '1') ||
                                    !modify
                                  "
                                ></v-autocomplete>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="1"
                                md="2"
                                v-if="
                                  editedItem.service == '0' &&
                                  prd_type == '02' &&
                                  invest
                                "
                              >
                                <v-checkbox
                                  v-model="editedItem.invest"
                                  label="Investissement"
                                  :readonly="
                                    !modify || editedItem.fk_template != null
                                  "
                                  class="ma-2"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            <!-- Modele-->
                            <v-row dense>
                              <v-col cols="12" sm="3" md="3">
                                <v-select
                                  v-model="editedItem.fk_template"
                                  :items="template_list"
                                  :item-value="'id'"
                                  :item-text="'label'"
                                  readonly
                                  :label="'Modele'"
                                  dense
                                >
                                </v-select>
                              </v-col>
                              <!-- Client-->
                              <v-col
                                cols="12"
                                sm="3"
                                md="4"
                                v-if="prd_type == '03' && spec"
                              >
                                <cursorselect
                                  :Qsearch="tierscursor"
                                  :Qresp="'tiers_cursor'"
                                  :value="editedItem.client_id"
                                  :text_fields="['code_client', 'nom']"
                                  :variableadd="variableadd"
                                  :label="'Client'"
                                  :rules="!editedItem.standard"
                                  :readonly="
                                    !modify ||
                                    (editedItem.client_id &&
                                      (editedItem.cmddetailcount > 0 ||
                                        editedItem.mvmdetailcount > 0))
                                  "
                                  :clearable="
                                    modify &&
                                    editedItem.cmddetailcount == 0 &&
                                    editedItem.mvmdetailcount == 0
                                  "
                                  @change="clientChange"
                                  :key="cs"
                                >
                                </cursorselect>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                v-if="prd_type == '03' && spec"
                              >
                                <v-checkbox
                                  v-model="editedItem.standard"
                                  label="Standard"
                                  :readonly="!modify"
                                  class="ma-2"
                                ></v-checkbox>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                v-if="
                                  prd_type == '03' &&
                                  editedItem.tosell == 1 &&
                                  cat_unit == 2 &&
                                  sell_net
                                "
                              >
                                <v-checkbox
                                  v-model="editedItem.sell_net"
                                  label="Vente net"
                                  :readonly="!modify"
                                  class="ma-2"
                                ></v-checkbox>
                              </v-col>
                              <!-- Categorie-->
                            </v-row>
                            <v-row dense>
                              <!-- Dernier prix achat -->
                              <v-col
                                cols="12"
                                sm="4"
                                md="1"
                                v-if="
                                  editedItem.service == '0' &&
                                  editedItem.id != -1 &&
                                  editedItem.tobuy &&
                                  ($store.state.auth.includes('01027') ||
                                    $store.state.isadmin)
                                "
                              >
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model.number="editedItem.prixachat"
                                  type="number"
                                  label="Dernier Prix d'achat"
                                  readonly
                                  disabled
                                  hide-spin-buttons
                                ></v-text-field>
                              </v-col>

                              <!-- Prix de vente std -->
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                lg="4"
                                v-if="
                                  editedItem.statut_id == '1' &&
                                  editedItem.tosell == 1
                                "
                              >
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  class="centered-input text--darken-3"
                                  :style="{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                  }"
                                  dense
                                  v-model.number="editedItem.prixvente"
                                  :rules="[
                                    (v) => !!v || 'Prix de Vente obligatoire',
                                  ]"
                                  type="number"
                                  label="Prix de Vente"
                                  hide-spin-buttons
                                ></v-text-field>
                              </v-col>

                              <!-- Taux Tva -->
                              <v-col cols="12" sm="4" md="1">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model.number="editedItem.tva_tx"
                                  type="number"
                                  label="Taux TVA"
                                  :rules="[
                                    (v) =>
                                      !v ||
                                      (v >= 0 && v < 100) ||
                                      'Valeur entre 0 et 99',
                                  ]"
                                  :readonly="
                                    !(
                                      $store.state.auth.includes('02027') ||
                                      $store.state.isadmin
                                    )
                                  "
                                  hide-spin-buttons
                                ></v-text-field>
                              </v-col>
                              <!-- Nomenclature douane -->
                              <v-col cols="12" sm="4" md="2">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.customcode"
                                  label="Nomenclature douanière"
                                  :readonly="
                                    (item.customcode &&
                                      editedItem.statut_id > '1') ||
                                    !modify
                                  "
                                ></v-text-field>
                              </v-col>
                              <!-- Type Barcode -->
                              <v-col cols="12" sm="4" md="4" lg="4">
                                <v-select
                                  :items="barcodetypes"
                                  v-model="editedItem.fk_barcode_type"
                                  item-text="libelle"
                                  item-value="id"
                                  label="Type de code-barres"
                                  outlined
                                  dense
                                  :readonly="
                                    (item.fk_barcode_type &&
                                      editedItem.statut_id > '1') ||
                                    !modify
                                  "
                                ></v-select>
                              </v-col>
                              <!-- Barcode -->
                              <v-col cols="12" sm="4" md="4" lg="4">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.barcode"
                                  label="Code-barres"
                                  required
                                  :readonly="
                                    (item.barcode &&
                                      editedItem.statut_id > '1') ||
                                    !modify
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <barcode
                                  :value="editedItem.barcode"
                                  :format="bartype"
                                  v-if="editedItem.fk_barcode_type != '17'"
                                >
                                </barcode>
                                <qr-code
                                  v-else
                                  :text="
                                    editedItem.barcode ? editedItem.barcode : ''
                                  "
                                  :size="150"
                                  error-level="L"
                                ></qr-code>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-divider vertical inset></v-divider>
                        <v-col cols="12" lg="4" md="4" sm="4">
                          <v-card flat class="pa-3">
                            <v-row>
                              <v-col cols="12" lg="12" md="12" sm="12">
                                <selecttree
                                  :items="cat_list"
                                  v-model="editedItem.categories"
                                  :value="editedItem.categories"
                                  :label="'Categories'"
                                  :nbr_chips="4"
                                  :readonly="!modify"
                                  :dense="true"
                                >
                                </selecttree>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                v-if="
                                  editedItem.tosell == '1' &&
                                  prd_type == '03' &&
                                  $store.state.options[0].lib_com == '1'
                                "
                              >
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.label_com"
                                  label="Libelle Commercial"
                                  :readonly="!modify"
                                ></v-text-field>
                              </v-col>
                              <!-- Description -->
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="editedItem.description"
                                  label="Description"
                                  :readonly="!modify"
                                ></v-text-field>
                              </v-col>
                              <!-- Image -->
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-row dense>
                                  <v-col cols="12" sm="5" md="5" lg="5">
                                    {{
                                      editedItem.tosell == 1 &&
                                      this.editedItem.bag_need == 1
                                        ? image_prod
                                        : "Image du produit"
                                    }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'url'"
                                      :can_upload="true"
                                      :can_preview="true"
                                      :can_download="true"
                                      :can_delete="true"
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="
                                        editedItem.bag_need ? 'u' : 'uf'
                                      "
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                v-if="
                                  editedItem.bag_need == 1 &&
                                  editedItem.tosell == 1
                                "
                              >
                                <v-row>
                                  <v-col cols="12" sm="5" md="5" lg="5">
                                    {{ "Aperçu" }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'preview_file'"
                                      :can_upload="true"
                                      :can_preview="true"
                                      :can_download="true"
                                      :can_delete="true"
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="'f'"
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                v-if="spec_tech"
                              >
                                <v-row>
                                  <v-col cols="12" sm="5" md="5" lg="5">
                                    {{
                                      editedItem.tosell == 1 &&
                                      this.editedItem.bag_need == 1
                                        ? spec_tech
                                        : "Spec. Technique"
                                    }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'spec_tech'"
                                      :can_upload="technique"
                                      :can_preview="true"
                                      :can_download="true"
                                      :can_delete="technique"
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="'f'"
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-row
                                  v-if="
                                    editedItem.bag_need == 1 &&
                                    editedItem.tosell == 1
                                  "
                                >
                                  <v-col cols="12" sm="2" md="2" lg="2">
                                    BAG
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1" lg="1">
                                    <v-tooltip bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          color="primary"
                                          class="pb-3"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="!editedItem.bag"
                                          @click="print_bag"
                                        >
                                          mdi-printer
                                        </v-icon>
                                      </template>
                                      <span>Imprimer le BAG</span>
                                    </v-tooltip>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1" lg="1">
                                    <v-icon
                                      class="pb-3"
                                      :color="
                                        editedItem.bag_send_date
                                          ? 'green'
                                          : 'primary'
                                      "
                                      v-if="
                                        editer &&
                                        editedItem.bag &&
                                        editedItem.statut_id == 12
                                      "
                                      @click="OpenEmailForm"
                                      >{{
                                        editedItem.bag_send_date
                                          ? "mdi-email-check-outline"
                                          : "mdi-email-arrow-right"
                                      }}
                                    </v-icon>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1" lg="1">
                                    <v-icon
                                      class="pb-3"
                                      color="primary"
                                      :disabled="!editedItem.bag"
                                      v-if="
                                        technique &&
                                        (editedItem.statut_id == '11' ||
                                          editedItem.statut_id == '13')
                                      "
                                      @click="valider_bag(12)"
                                    >
                                      mdi-check
                                    </v-icon>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'bag'"
                                      :can_upload="
                                        (editedItem.statut_id == 11 ||
                                          editedItem.statut_id == 13 ||
                                          editedItem.statut_id == 2) &&
                                        technique
                                      "
                                      :can_preview="false"
                                      :can_download="false"
                                      :can_delete="
                                        (editedItem.statut_id == 11 ||
                                          editedItem.statut_id == 13 ||
                                          editedItem.statut_id == 2) &&
                                        technique
                                      "
                                      box_filter="image/png, image/jpeg, image/webp, image/gif"
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="'f'"
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-row
                                  v-if="
                                    editedItem.bag_need == 1 &&
                                    editedItem.tosell == 1 &&
                                    (editedItem.statut_id == 12 ||
                                      editedItem.statut_id == 1 ||
                                      editedItem.statut_id == 2)
                                  "
                                >
                                  <v-col cols="12" sm="3" md="3" lg="3">
                                    BAG Confirmé
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1" lg="1">
                                    <v-tooltip bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          text
                                          class="mx-1"
                                          color="primary"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="!editedItem.bag_confirm"
                                          @click="valider_bag(1)"
                                          v-if="
                                            editer && editedItem.statut_id == 12
                                          "
                                        >
                                          <v-icon> mdi-check </v-icon>
                                        </v-btn>
                                      </template>
                                      <span
                                        >Valider la confirmation du BAG</span
                                      >
                                    </v-tooltip>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1" lg="1">
                                    <v-tooltip bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          text
                                          class="mx-1"
                                          color="primary"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="
                                            editedItem.bag_confirm != null
                                          "
                                          @click="valider_bag(13)"
                                          v-if="
                                            editer && editedItem.statut_id == 12
                                          "
                                        >
                                          <v-icon> mdi-cancel </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Reserve Client</span>
                                    </v-tooltip>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'bag_confirm'"
                                      :can_upload="
                                        editedItem.statut_id == 12 ||
                                        editedItem.statut_id == 2
                                      "
                                      :can_preview="true"
                                      :can_download="true"
                                      :can_delete="
                                        editedItem.statut_id == 12 ||
                                        editedItem.statut_id == 2
                                      "
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="'f'"
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                                <v-divider
                                  v-if="
                                    editedItem.bag_need == 1 &&
                                    editedItem.tosell == 1 &&
                                    (editedItem.statut_id == 1 ||
                                      editedItem.statut_id == 2)
                                  "
                                ></v-divider>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                v-if="
                                  editedItem.bag_need == 1 &&
                                  editedItem.tosell == 1 &&
                                  (editedItem.statut_id == 1 ||
                                    editedItem.statut_id == 2)
                                "
                              >
                                <v-row>
                                  <v-col cols="12" sm="5" md="5" lg="5">
                                    {{ "Fichier de travail" }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="7"
                                    md="7"
                                    lg="7"
                                    class="text-right"
                                  >
                                    <downloadcomp
                                      :item="editedItem"
                                      :field="'work_file'"
                                      :can_upload="
                                        (editedItem.statut_id == 1 ||
                                          editedItem.statut_id == 2) &&
                                        technique
                                      "
                                      :can_preview="true"
                                      :can_download="true"
                                      :can_delete="
                                        (editedItem.statut_id == 1 ||
                                          editedItem.statut_id == 2) &&
                                        technique
                                      "
                                      :update_query="update_query"
                                      :var_name="'product'"
                                      @file_uploaded="file_uploaded"
                                      @file_deleted="file_deleted"
                                      :top="3"
                                      :key="kdc"
                                      :upload_type="'u'"
                                    >
                                    </downloadcomp>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- Barcode -->
                              <v-col cols="12" sm="12" md="12" lg="12"> </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="4" md="5" v-if="!spec">
                            <listitems
                              :list="detail_list"
                              :qDelete="Qtiertypedelete"
                              :Get_suf="'TierTypePrix'"
                              title="Par Type Client"
                              :headers="detailheaders"
                              :master="false"
                              :add="
                                modify &&
                                ($store.state.auth.includes('02027') ||
                                  $store.state.isadmin)
                              "
                              :del="
                                modify &&
                                ($store.state.auth.includes('02027') ||
                                  $store.state.isadmin)
                              "
                              :showedit="false"
                              :key="ttp"
                              @open="OpenTTPForm"
                            >
                            </listitems>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="2"
                            v-if="price_qte_list.length == 0"
                          >
                            <v-text-field
                              autocomplete="off"
                              class="centered-input text--darken-3"
                              :style="{
                                fontSize: '20px',
                                fontWeight: 'bold',
                              }"
                              outlined
                              dense
                              v-model.number="editedItem.prixvente"
                              type="number"
                              label="Prix de Vente"
                              :readonly="
                                !modify ||
                                !(
                                  $store.state.auth.includes('02027') ||
                                  $store.state.isadmin
                                )
                              "
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="5">
                            <listitems
                              :list="price_qte_list"
                              :qDelete="Qprice_qte_delete"
                              :Get_suf="'ProductPrice'"
                              title="Par Quantite"
                              :headers="price_qte_headers"
                              :master="false"
                              :add="
                                modify &&
                                ($store.state.auth.includes('02027') ||
                                  $store.state.isadmin)
                              "
                              :del="
                                modify &&
                                ($store.state.auth.includes('02027') ||
                                  $store.state.isadmin)
                              "
                              :showedit="false"
                              :key="pq"
                              @open="OpenQPForm"
                            >
                            </listitems>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'3'">
                  <v-card flat class="pa-3">
                    <v-row dense>
                      <v-col cols="12" sm="8" md="8">
                        <v-row dense>
                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              :items="depots"
                              v-model="editedItem.depot_id"
                              item-text="label"
                              item-value="id"
                              label="Entrepôt par défaut"
                              dense
                              readonly
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.stock_ini"
                              label="Stock Initial"
                              required
                              type="number"
                              readonly
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.seuil_stock_alerte"
                              label="Stock alerte"
                              required
                              type="number"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="editedItem.tobuy == '1'"
                          >
                            <v-autocomplete
                              :items="
                                units.filter((elm) => elm.uom_type == 'm')
                              "
                              v-model="editedItem.fk_unit_buy"
                              item-text="name"
                              item-value="id"
                              label="Unité d'Achat"
                              :rules="[
                                (v) => !!v || 'Unité d\'Achat obligatoire',
                              ]"
                              outlined
                              dense
                              :readonly="!modify"
                              @change="unit_change"
                            >
                              <template v-slot:item="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{
                                  item.name + " (" + item.category_name + ")"
                                }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <!-- Facteur -->
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="editedItem.tobuy == '1'"
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              type="number"
                              v-model.number="editedItem.factor"
                              label="Facteur"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  (editedItem.fk_unit !=
                                    editedItem.fk_unit_buy &&
                                    parseFloat(editedItem.factor) == 0) ||
                                  'Facteur obligatoire',
                              ]"
                              :readonly="!modify"
                              :disabled="
                                cat_buy == cat_unit || !editedItem.fk_unit_buy
                              "
                              hide-spin-buttons
                              hint="Unité Achat * facteur = Unité Mesure"
                            ></v-text-field>
                          </v-col>
                          <!-- Facteur -->
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="
                              editedItem.tosell == '1' &&
                              use_balance &&
                              cat_unit != 2
                            "
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              type="number"
                              v-model.number="editedItem.factor"
                              :label="unit_name + '/Kg'"
                              :rules="[(v) => !!v || 'Facteur obligatoire']"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="editedItem.service == '0'"
                          >
                            <v-checkbox
                              v-model="editedItem.bylist"
                              label="Liste/Lot"
                              :readonly="
                                !modify || editedItem.mvmdetailcount > 0
                              "
                              class="ma-2"
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.minvalue"
                              :label="
                                'Min ' +
                                (editedItem.unit ? editedItem.unit : 'Quantite')
                              "
                              type="number"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.maxvalue"
                              :label="
                                'Max ' +
                                (editedItem.unit ? editedItem.unit : 'Quantite')
                              "
                              type="number"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="7"
                            md="7"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-autocomplete
                              :items="
                                units.filter((elm) => elm.uom_type == 'c')
                              "
                              v-model="editedItem.fk_unit_colis"
                              item-text="name"
                              item-value="id"
                              label="Unité des items"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  editedItem.bylist == 0 ||
                                  'Unité des items obligatoire',
                              ]"
                              outlined
                              dense
                              :readonly="!modify"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-checkbox
                              v-model="editedItem.partial"
                              label="Sortie Partielle"
                              :readonly="
                                !modify || editedItem.mvmdetailcount > 0
                              "
                              class="ma-2"
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-checkbox
                              v-model="editedItem.is_expire"
                              label="Expirable"
                              :readonly="
                                !modify || editedItem.mvmdetailcount > 0
                              "
                              class="ma-2"
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            v-if="editedItem.is_expire == 1"
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.expire_delai"
                              label="
                                    Délai de rappel (jours)
                                  "
                              type="number"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            v-if="
                              editedItem.is_expire == 1 &&
                              editedItem.tomanufacture == 1
                            "
                          >
                            <v-text-field
                              autocomplete="off"
                              outlined
                              dense
                              v-model.number="editedItem.life"
                              label="
                                    Durée de vie (jours)
                                  "
                              :rules="[
                                (v) => !!v || 'Durée de vie obligatoire',
                              ]"
                              type="number"
                              :readonly="!modify"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-select
                              v-model="editedItem.fifo"
                              :items="fifo_list"
                              label="Sortie Stock"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="12"
                            v-if="editedItem.bylist == 1"
                          >
                            <v-select
                              :items="colis_attributs"
                              v-model="editedItem.tabcolisattributs"
                              item-text="name"
                              item-value="id"
                              label="Attributs de Colisage"
                              outlined
                              dense
                              :readonly="!modify"
                              multiple
                              small-chips
                              v-on:input="limiter"
                              :disabled="editedItem.bylist == 0"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'4'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <listitems
                            :list="articles_list"
                            :qDelete="Qproductarticledelete"
                            :Get_suf="'ProductArticle'"
                            :title="'Fournisseurs'"
                            :headers="articles_headers"
                            :master="true"
                            :add="modify"
                            :del="modify"
                            :del_disable="
                              tierdetail.nbcmd > 0 || tierdetail.nbmvm > 0
                            "
                            :showedit="false"
                            :key="tier_key"
                            @rowselect="SelectArticleForm"
                            @open="OpenArticleForm"
                            @dd_click="article_statut_change"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="'5'">
                  <v-card flat>
                    <v-card-text>
                      <v-tabs v-model="tab2" class="my-tabs">
                        <v-tab key="1" href="#1"> Attributs </v-tab>
                        <v-tab
                          key="2"
                          href="#2"
                          v-if="
                            editedItem.service == '0' &&
                            editedItem.id != -1 &&
                            editedItem.tomanufacture == 1
                          "
                        >
                          Recette
                        </v-tab>

                        <v-tab-item :value="'1'">
                          <v-row>
                            <v-col cols="12" sm="4" md="6">
                              <listitems
                                :selitem="selitem"
                                :list="
                                  attributs_list.filter((elm) => elm.id > 0)
                                "
                                title="Attribut"
                                :headers="attribut_headers"
                                :master="false"
                                :add="false"
                                :del="false"
                                @open="attribut_change"
                                @rowselect="AttributSelect"
                                :Update="
                                  modify && editedItem.cmddetailcount == 0
                                "
                                :ipg="-1"
                                :showedit="false"
                                :exp_excel="false"
                                :key="kattribut"
                              >
                              </listitems>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                        <v-tab-item :value="'2'">
                          <v-card flat>
                            <v-card-text>
                              <v-form
                                ref="form3"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col cols="12" sm="2" md="2">
                                    <v-select
                                      :items="boms"
                                      v-model="editedItem.process_id"
                                      item-text="process_name"
                                      item-value="process_id"
                                      label="Processus de Fabrication"
                                      readonly
                                      outlined
                                      dense
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-row
                                      v-for="(bom, index) in boms_values"
                                      :key="index"
                                      dense
                                    >
                                      <v-col cols="12" sm="2" md="2">
                                        {{ bom.gamme_parent_name }}
                                      </v-col>
                                      <v-col cols="12" sm="2" md="2">
                                        {{ bom.caption }}
                                        <v-btn
                                          color="blue darken-1"
                                          @click="bom_add(bom, index)"
                                          small
                                          v-if="bom.multiple"
                                        >
                                          <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <v-btn
                                          color="blue darken-1"
                                          @click="bom_del(index)"
                                          small
                                          v-if="bom.new"
                                        >
                                          <v-icon>mdi-minus</v-icon>
                                        </v-btn>
                                      </v-col>

                                      <v-col
                                        cols="12"
                                        :sd="2"
                                        :md="2"
                                        v-for="attribute in bom.attributes"
                                        :key="attribute.attribute_id"
                                      >
                                        <v-text-field
                                          v-model="attribute.value"
                                          :label="
                                            attribute.name +
                                            (attribute.suffixe
                                              ? '(' + attribute.suffixe + ')'
                                              : '')
                                          "
                                          dense
                                          :readonly="attribute.readonly"
                                          @focus="$event.target.select()"
                                          v-if="
                                            att_values(attribute.attribute_id)
                                              .length == 0
                                          "
                                          @input="boms_change++"
                                        ></v-text-field>
                                        <v-autocomplete
                                          v-model="attribute.value_id"
                                          :items="
                                            att_values(attribute.attribute_id)
                                          "
                                          item-value="id"
                                          item-text="name"
                                          :label="
                                            attribute.name +
                                            (attribute.suffixe
                                              ? '(' + attribute.suffixe + ')'
                                              : '')
                                          "
                                          dense
                                          small-chips
                                          :readonly="attribute.readonly"
                                          @change="boms_change++"
                                          :rules="[
                                            (v) =>
                                              !!v ||
                                              attribute.name + ' obligatoire',
                                          ]"
                                          v-else
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="12" sm="1" md="1">
                                        <v-text-field
                                          autocomplete="off"
                                          type="number"
                                          v-model.number="bom.dechet"
                                          :label="'Dechet (%)'"
                                          dense
                                          outlined
                                          @focus="$event.target.select()"
                                          @input="dechet_change(bom)"
                                          hide-spin-buttons
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="1" md="1">
                                        <v-text-field
                                          autocomplete="off"
                                          type="number"
                                          v-model.number="bom.ratio"
                                          :label="
                                            'Ratio ' +
                                            ' (' +
                                            (bom.support == 1
                                              ? 'ml'
                                              : bom.ratio_unit_name) +
                                            ')'
                                          "
                                          :rules="[
                                            (v) => !!v || 'Ratio obligatoire',
                                          ]"
                                          dense
                                          outlined
                                          @focus="$event.target.select()"
                                          @input="boms_change++"
                                          hide-spin-buttons
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" sm="10" md="10"> </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="faisabilite"
                                      :disabled="
                                        editedItem.bom_ok == 0 ||
                                        boms_change > 0
                                      "
                                    >
                                      Faisabilite
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="save_boms"
                                      :disabled="boms_change == 0"
                                    >
                                      Enregistrer
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-col
                                cols="12"
                                sm="6"
                                md="4"
                                v-if="
                                  $store.state.auth.includes('01027') ||
                                  $store.state.isadmin
                                "
                              >
                                <v-text-field
                                  autocomplete="off"
                                  class="inputPrice"
                                  type="number"
                                  dense
                                  v-model.number="editedItem.prixrevient"
                                  :readonly="!modify"
                                  :label="
                                    'Coût de revient' +
                                    (editedItem.unit
                                      ? '(DZD/' + editedItem.unit + ')'
                                      : '')
                                  "
                                  hide-spin-buttons
                                  @focus="$event.target.select()"
                                ></v-text-field>
                              </v-col>
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'6'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="8">
                          <listitems
                            :list="links_list"
                            :qCreate="Qlinkcreate"
                            :qUpdate="Qlinkupdate"
                            :qDelete="Qlinkdelete"
                            :Get_suf="'ProductLink'"
                            :title="'Articles Associés'"
                            :headers="link_headers"
                            :master="false"
                            :exp_excel="false"
                            :add="modify && editedItem.mvmdetailcount == 0"
                            :del="modify && editedItem.mvmdetailcount == 0"
                            :filename="'Linked'"
                            :sheetname="'Linked'"
                            :showstd="true"
                            :key="klink"
                            :push="true"
                            @add="refresh_link"
                            @delete="refresh_link"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'7'">
                  <filelist
                    :item="editedItem"
                    :isform="false"
                    :key="fdocl"
                    :editer="modify"
                    :doc_type="7"
                    :auth="auth"
                  >
                  </filelist>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>

          <v-navigation-drawer
            v-model="drawer2"
            right
            absolute
            temporary
            :width="drawer2 ? 600 : 0"
          >
            <comments
              ref="comments"
              :title="'Suivi Info. Produit'"
              :comments="comments"
              :document_type="20"
              :doc_id="parseInt(editedItem.id)"
              :ct="false"
              @note_added="note_added"
            ></comments>
          </v-navigation-drawer>
          <timeline
            :document_type="7"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="'Produit'"
            :doc_code="editedItem.code"
          >
          </timeline>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    </template>
    <tiertypeprixform
      :item="ttpdetail"
      :items="detail_list"
      :key="ttp"
      :readonly="!$store.state.isadmin"
      :showForm="!isTTPClosed"
      @close="closeTTPForm"
      :tiers_types="tiers_types"
    >
    </tiertypeprixform>

    <articleform
      :item="tierdetail"
      :product="editedItem"
      :items="selected_tiers"
      :key="tier_key"
      :readonly="!modify"
      :showForm="!isTierClosed"
      :cat_list="cat_tier"
      @close="closeFournisForm"
      @refresh="close"
      :parametre="2"
    >
    </articleform>
    <faisabilite
      :showForm="!isFaisabiliteClosed"
      :product="editedItem"
      :produit_id="parseInt(editedItem.id)"
      :key="kfais"
      :units="units"
      :templates="templates"
      :attributes="attributes"
      @close="isFaisabiliteClosed = true"
    >
    </faisabilite>
    <qteprixform
      :item="qpdetail"
      :items="price_qte_list"
      :key="pq"
      :readonly="!modify"
      :showForm="!isQPClosed"
      @close="closeQPForm"
      @add="add_price"
    >
    </qteprixform>

    <formstd
      :item="attribut"
      :items="attributs_list"
      :headers="attribut_headers"
      :Get_suf="'ProductAttributeValue'"
      :key="keyform"
      :Update="true"
      :showForm="!isClosed2"
      @close="closeForm"
      @change="attribut_save"
      :title="f_title"
    >
    </formstd>
    <emailform
      :showForm="!isEmailClosed"
      :client="editedItem.client_name"
      :adresse="editedItem.client_email"
      :product="editedItem"
      :productcode="editedItem.code"
      :key="kem"
      :bag="editedItem.bag"
      @close="isEmailClosed = true"
      @success="email_success"
    ></emailform>
    <!-- File Upload Progress Component -->
    <FileUploadProgress
      :isProcessing="isProcessing"
      :uploading="false"
      :key="pl"
    />
  </div>
</template>

<script>
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

import INSERT_PRODUCT from "../graphql/Product/INSERT_PRODUCT.gql";
import PRODUCT_UPDATE from "../graphql/Product/PRODUCT_UPDATE.gql";
import UPDATE_PRODUCT from "../graphql/Product/UPDATE_PRODUCT.gql";
import DELETE_TIER_TYPE from "../graphql/Product/DELETE_TIER_TYPE.gql";
import DELETE_PRODUCT_CLIENT from "../graphql/Product/DELETE_PRODUCT_CLIENT.gql";
import CREATE_PRODUCT_LINK from "../graphql/Product/CREATE_PRODUCT_LINK.gql";
import UPDATE_PRODUCT_LINK from "../graphql/Product/UPDATE_PRODUCT_LINK.gql";
import DELETE_PRODUCT_LINK from "../graphql/Product/DELETE_PRODUCT_LINK.gql";
import DELETE_PRODUCT_ARTICLE from "../graphql/Product/DELETE_PRODUCT_ARTICLE.gql";
import DELETE_PRODUCT_PRIX from "../graphql/Product/DELETE_PRODUCT_PRIX.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import UPSERT_DOC from "../graphql/File/UPSERT_DOC.gql";
import { api, sell_net, use_balance } from "print/data.js";
import { generatePdf } from "print/bag_generator";
import ALLCODE from "../graphql/Product/ALLCODE.gql";
import UPDATE_PRODUCT_ARTICLE from "../graphql/Product/UPDATE_PRODUCT_ARTICLE.gql";
//import UPDATE_PRODUCT_ATTRIBUT_VALUE from "../graphql/Template/UPDATE_PRODUCT_ATTRIBUT_VALUE.gql";

import {
  spec,
  pc,
  moule,
  invest,
  version,
  spec_tech,
  image_prod,
  bag,
} from "print/data.js";
import { images } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    barcode: () => import("vue-barcode"),
    "qr-code": () => import("vue-qrcode-component"),
    listitems: () => import("../components/ListItems.vue"),
    tiertypeprixform: () => import("../components/TierTypePrixForm.vue"),
    articleform: () => import("../components/ArticleForm.vue"),
    selecttree: () => import("../components/SelectTree.vue"),
    qteprixform: () => import("../components/QtePrixForm.vue"),
    filelist: () => import("../components/FileList.vue"),
    formstd: () => import("../components/FormStd.vue"),
    cursorselect: () => import("../components/CursorSelect.vue"),
    timeline: () => import("../components/Timeline.vue"),
    faisabilite: () => import("../components/Faisabilite.vue"),
    downloadcomp: () => import("../components/DownloadComp.vue"),
    emailform: () => import("../components/EmailForm.vue"),
    comments: () => import("../components/Comment.vue"),
    FileUploadProgress: () => import("../components/FileUploadProgress.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    templates: Array,
    attributes: Array,
    values_list: Array,
    banks: Array,
    countrys: Array,
    cat_tier: Array,
    cat_product: Array,
    cat_familles: Array,
    barcodetypes: Array,
    plancomptable: Array,
    depots: Array,
    tiers_types: Array,
    units: Array,
    prd_type: String,
    editer: Boolean,
    technique: Boolean,
    valider: Boolean,
    new_item_code: String,
    process: Array,
    boms: Array,

    allarticles: Array,
    route: Object,
  },
  data: () => ({
    isProcessing: false,
    isFaisabiliteClosed: true,
    boms_change: 0,
    selitem: {},
    drawer2: null,
    tab1: 1,
    tab2: 1,
    is_motif: false,
    currentTab: 1,
    modify: false,
    lib: true,
    lc: 0,
    cs: 250,
    kval: 150,
    kdc: 1489,
    drawer: null,
    libv: false,
    f_title: "",
    cat_buy: null,
    cat_unit: null,
    unit_name: null,
    progress: false,
    isSelecting: false,
    title: "",
    status_list: [],
    fab: false,
    uploading: false,
    isClosed2: true,
    loading2: false,
    queue: [],
    fifo_list: ["FIFO", "LIFO", "FEFO"],
    ttp: 100,
    tp: 200,
    tier_key: 300,
    fp: 400,
    pq: 500,
    frml: 600,
    kpd: 800,
    kfais: 900,
    fdocl: 1000,
    pl: 1256,
    kblist: 1400,
    kg: 1500,
    kattribut: 1600,
    klink: 1700,
    keyform: 1800,
    change: 0,
    cat_change: 0,
    minchange: 1,
    tabs: 3,
    x: "",
    valid: true,
    datepicker1: false,
    catpicker1: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    defaultItem: {
      id: -1,
      created_at: null,
      cmddetailcount: 0,
      mvmdetailcount: 0,
      service: "0",
      decimal: 2,
      categories: [],
      docs: [],
      tier_type_prix: [],
      tabcolisattributs: [],
      qte_prix: [],
      boms: [],
      articles: [],
      barcode: "",
      statut_id: "1",
    },
    bag_true_list: [
      { id: "1", label: "Att. Validation" },
      { id: "2", label: "Actif" },
      { id: "3", label: "A surveiller" },
      { id: "4", label: "Bloqué" },
      { id: "11", label: "Att. BAG" },
      { id: "12", label: "Att. Conf. BAG" },
      { id: "13", label: "Att. Trait. Reserves" },
    ],
    bag_false_list: [
      { id: "1", label: "Att. Validation" },
      { id: "2", label: "Actif" },
      { id: "3", label: "A surveiller" },
      { id: "4", label: "Bloqué" },
    ],

    link_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Article",
        value: "link_id",
        selected: true,
        hiden: true,
        edit: true,
        rules: [(v) => !!v || "Article obligatoire"],
        type: "cur_product",
        label: "label",
        code: "code",
        variableadd: {
          Kind: 6,
        },
        whereadd: [{ column: "ID", operator: "NEQ", value: -1 }],
        sm: 12,
        md: 12,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },

      {
        text: "Ratio",
        value: "ratio",
        selected: true,
        rules: [(v) => !!v || "Ratio obligatoire"],
        edit: true,
        type: "number",
        sm: 4,
        md: 4,
      },
      {
        text: "article_id",
        value: "article_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],

    detailheaders: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Type Client",
        value: "tier_type",
        slot: "href",
        selected: true,
      },
      {
        text: "Prix HT",
        value: "prix",
        selected: true,
        align: "end",
        slot: "cur",
      },
    ],
    tier_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Client",
        value: "nom",
        slot: "href",
        selected: true,
      },
      {
        text: "Prix HT",
        value: "prix",
        selected: true,
        align: "end",
        slot: "cur",
      },
    ],
    price_qte_headers: [
      {
        text: "N°",
        value: "no",
        slot: "href",
        selected: true,
      },
      {
        text: "Debut",
        value: "qte_from",
        selected: true,
      },
      {
        text: "Fin",
        value: "qte_to",
        selected: true,
      },
      {
        text: "Prix HT",
        value: "prix",
        selected: true,
        align: "end",
        slot: "cur",
      },
    ],
    attribut_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Attribut",
        value: "name",
        slot: "href",
        selected: true,
      },
      {
        text: "Valeur",
        value: "value",
        selected: true,
      },
      {
        text: "Valeur",
        value: "value",
        selected: true,
        hiden: true,
        slot: "simple-select",
        edit: true,
        type: "select",
        select_list: [],
        show_list: [],
        item_value: "id",
        item_text: "name",
        default: "",
        sm: 12,
        md: 12,
      },
    ],

    articles_headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Code Fournisseur",
        value: "code_fournisseur",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        slot: "href",
        selected: true,
      },
      {
        text: "Designation Externe",
        value: "label_ext",
        selected: true,
      },
      {
        text: "Ref.Externe",
        value: "ref_ext",
        selected: true,
      },
      {
        text: "Prix d'achat",
        value: "prixachat",
        hiden: true,
        selected: true,
      },
      {
        text: "Devise",
        value: "devise",
        hiden: true,
        selected: true,
      },
      {
        text: "Dernier Achat",
        value: "date_dern_achat",
        slot: "date",
        selected: false,
      },

      {
        text: "Delai",
        value: "delai",
        selected: false,
      },
      {
        text: "Qualite",
        value: "qualite",
        selected: false,
      },

      {
        text: "Dernier Mouvement",
        value: "date_dern_mvm",
        slot: "date",
        selected: false,
      },

      {
        text: "Statut",
        value: "statuts_name",
        slot: "dropdown",
        dd_items: [
          { id: "1", title: "Temporaire", color: "gray" },
          { id: "2", title: "Actif", color: "green" },
          { id: "3", title: "Archive", color: "orange" },
          { id: "4", title: "Obsolète", color: "red" },
        ],
        color: "color",
        column: "statut_id",
        valeur: "3",
        selected: true,
      },
    ],
    attribut: {},
    isTTPClosed: true,
    isTierClosed: true,
    ttpdetail: {},
    isTPClosed: true,
    isQPClosed: true,
    isEmailClosed: true,
    kem: 4587,
    tpdetail: {},
    tierdetail: {},
    fpdetail: {},
    qpdetail: {},
    selected_tiers: [],
    ref_code: true,
    loading: false,

    tiers_list: [],
    boms_values: [],
  }),

  computed: {
    use_balance() {
      return use_balance;
    },
    sell_net() {
      return sell_net;
    },
    spec_tech() {
      return spec_tech;
    },
    image_prod() {
      return image_prod;
    },

    update_query() {
      return UPDATE_PRODUCT;
    },
    today() {
      return this.$store.state.today;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    variableadd() {
      let w = {
        TypeScope: 2,
        statut_id: [2, 3],
      };
      return w;
    },
    auth() {
      let a =
        this.prd_type == "02"
          ? "01002"
          : this.prd_type == "03"
          ? "01003"
          : this.prd_type == "04"
          ? "01004"
          : "01034";
      return a;
    },

    cat_list() {
      return this.cat_product.filter(
        (elm) => elm.type_cat == (this.editedItem.service == "0" ? 2 : 3)
      );
    },
    template_list() {
      return this.prd_type == "03" || this.prd_type == "04"
        ? this.templates.filter((elm) => elm.tosell == 1)
        : this.templates.filter((elm) => elm.tobuy == 1);
    },

    products_types() {
      return this.$store.state.products_types.filter(
        (elm) => elm.nature == this.editedItem.service
      );
    },
    version() {
      return version;
    },

    images() {
      return images;
    },
    spec() {
      return spec;
    },
    invest() {
      return invest;
    },
    moule() {
      return moule;
    },

    pc() {
      return pc;
    },
    colis_attributs() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },

    Qtiertypedelete() {
      return DELETE_TIER_TYPE;
    },
    Qlinkcreate() {
      return CREATE_PRODUCT_LINK;
    },
    Qlinkupdate() {
      return UPDATE_PRODUCT_LINK;
    },
    Qlinkdelete() {
      return DELETE_PRODUCT_LINK;
    },
    Qproductclientdelete() {
      return DELETE_PRODUCT_CLIENT;
    },
    Qproductarticledelete() {
      return DELETE_PRODUCT_ARTICLE;
    },
    Qprice_qte_delete() {
      return DELETE_PRODUCT_PRIX;
    },
    detail_list: function () {
      return this.editedItem.id > 0 ? this.editedItem.tier_type_prix : [];
    },
    price_qte_list: function () {
      let l = [];
      if (this.editedItem.qte_prix) l = this.editedItem.qte_prix;
      return l;
    },
    clients_list: function () {
      return this.tiers_list ? this.tiers_list : [];
    },
    attributs_list: function () {
      let l = [];
      if (this.editedItem.attributs)
        l =
          this.prd_type == "04"
            ? this.editedItem.attributs
            : this.editedItem.attributs.filter((elm) =>
                this.prd_type == "03" ? elm.technique == 0 : true
              );
      return l;
    },
    manuf_article: function () {
      let l = [];
      if (this.editedItem.articles)
        l = this.editedItem.articles.filter((elm) => elm.tier_id == 1);
      return l;
    },
    links_list: function () {
      let l = [];
      if (this.manuf_article)
        if (this.manuf_article.length > 0)
          if (this.manuf_article[0].links) l = this.manuf_article[0].links;
      return l;
    },

    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },
    articles_list: function () {
      let l = [];
      if (this.editedItem.articles)
        l = this.editedItem.articles.filter((elm) => elm.tier_id != 1);
      return l;
    },

    boms_list: function () {
      let list = this.boms.filter(
        (elm) =>
          elm.process_id == this.editedItem.process_id &&
          elm.template_id != null
      );
      return list;
    },

    bartype() {
      let c;
      if (this.editedItem.fk_barcode_type) {
        let i = this.barcodetypes.findIndex(
          (elm) => elm.id == this.editedItem.fk_barcode_type
        );
        if (i >= 0) c = this.barcodetypes[i].code;
      }

      return c;
    },

    formTitle() {
      return this.editedItem.id < 0
        ? "Nouveau Produit  "
        : "Produit : " + this.editedItem.label;
    },
    comments: function () {
      return this.editedItem.comments ? this.editedItem.comments : [];
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.change++;
      },
      deep: true,
    },
    "editedItem.categories": {
      handler() {
        this.cat_change++;
      },
      deep: true,
    },
  },

  created() {
    if (!this.valider) {
      this.articles_headers[12].slot = "chip";
    }
  },
  async mounted() {
    this.status_list = this.bag_false_list;
    if (bag) this.status_list = this.bag_true_list;
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.kdc++;

        //linked product

        // let l = this.allarticles.filter(
        //   (elm) =>
        //     elm.produit_id != this.editedItem.id &&
        //     (elm.tosell == 1 || elm.tomanufacture == 1)
        // );
        // this.link_headers[2].select_list = l;
        // this.link_headers[3].show_list = l;
        this.refresh_link();
        this.get_boms();
        this.cs++;
        if (this.manuf_article)
          if (this.manuf_article.length > 0)
            this.link_headers[5].default = this.manuf_article[0].id;
        this.klink++;
        // this.cs++;

        this.articles_headers[6].text = "Prix d'achat /" + this.editedItem.unit;
        this.kpd++;
        this.unit_change();
      } else {
        if (!this.route.route_name) {
          this.defaultItem.created_at = this.$store.state.today;
          this.editedItem = Object.assign({}, this.defaultItem);
        } else this.editedItem = Object.assign({}, this.item);
        this.editedItem.created_at = this.$store.state.today;
        if (this.prd_type == "04") this.editedItem.service = "0";
        this.editedItem.tomanufacture =
          this.prd_type == "04" || this.prd_type == "34";
        this.editedItem.tosell = this.prd_type == "03";
        this.editedItem.tobuy = this.prd_type == "02" || this.version == 1;

        this.editedItem.code = this.new_item_code;
        this.editedItem.tva_tx =
          this.$store.state.options[0].default_product_tva;
        if (this.products_types.length == 1)
          this.editedItem.fk_product_type = this.products_types[0].id;
      }
    }
    this.modify =
      this.editer &&
      (this.editedItem.id < 0 ||
        this.editedItem.statut_id != 3 ||
        this.$store.state.isadmin);
    this.articles_headers[6].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );
    this.articles_headers[7].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );
  },

  methods: {
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async valider_bag(status) {
      this.is_motif = status == 13;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Valider cette action ?' "
        )
      ) {
        let b = this.editedItem.bag;
        let v = {
          product: {
            id: this.editedItem.id,
            statut_id: status,
          },
        };
        if (status == 13)
          v = {
            product: {
              id: this.editedItem.id,
              bag: null,
              bag_send_date: null,
              statut_id: status,
            },
          };
        let r = await this.maj(UPDATE_PRODUCT, v);
        if (r) {
          this.editedItem.statut_id = status;
          if (status == 13) this.editedItem.bag = null;
          this.kdc++;
          this.$nextTick(() => {
            this.change = this.minchange;
          });
          if (this.is_motif) {
            this.$refs.comments.save_ext_comment(this.editedItem.motif, 4);
            await this.maj(UPSERT_DOC, {
              input: [
                {
                  title: "Fichier BAG Obsolete",
                  description: "Reserves client emise sur le BAG",
                  url: JSON.parse(b).url,
                  filename: JSON.parse(b).filename,
                  doc_date: JSON.parse(b).upload_date,
                  isurl: 0,
                  doc_id: this.editedItem.id,
                  doc_type: 7,
                  write_uid: this.$store.state.me.id,
                },
              ],
            });
          }
          this.$store.dispatch("Changed", true);
        }
      }
      this.is_motif = false;
    },
    print_bag() {
      this.isProcessing = true;
      let FileUrl = JSON.parse(this.editedItem.bag).url;
      generatePdf(api, FileUrl, this.editedItem, (doc) => {
        this.isProcessing = false;
        doc.setProperties({ title: `${this.editedItem.label}.pdf` });
        window.open(doc.output("bloburl"), "_blank"); // Preview in a new tab
      });
    },
    send_bag() {},

    file_uploaded(data, field) {
      this.editedItem[field] = data;
      this.$nextTick(() => {
        this.change = this.minchange;
      });
      this.kdc++;
      this.$emit("file_changed");
      this.$store.dispatch("Changed", true);
    },
    file_deleted(field) {
      this.editedItem[field] = null;
      this.$nextTick(() => {
        this.change = this.minchange;
      });
      this.$emit("file_changed");
      this.$store.dispatch("Changed", true);
    },
    get_boms() {
      //boms
      this.boms_values = [];
      let boms = [];
      if (this.editedItem.boms) {
        boms = JSON.parse(this.editedItem.boms);
      }

      boms.forEach((element) => {
        let i = this.boms_list.findIndex(
          (elm) => elm.id == element.fk_process_gamme_bom
        );

        if (i >= 0) {
          let j2 = this.templates.findIndex(
            (elm) => elm.id == element.fk_template
          );
          if (j2 >= 0) {
            element.support = this.templates[j2].support;
            let j3 = this.units.findIndex(
              (elm) => elm.id == this.templates[j2].fk_unit_ratio
            );
            if (j3 >= 0) element.ratio_unit_name = this.units[j3].name;
          }
          element.template_id = element.fk_template;
          element.caption = this.boms_list[i].caption;
          element.gamme_parent_name = this.boms_list[i].gamme_parent_name;

          element.attributes.forEach((attribut) => {
            let j = this.attributes.findIndex(
              (elm) => elm.id == attribut.attribute_id
            );
            if (j >= 0) {
              //  attribut.readonly = this.attributes[j].technique == 0;
              attribut.name = this.attributes[j].name;
              attribut.suffixe = this.attributes[j].suffixe;
              attribut.control = this.attributes[j].control;
            }
          });

          this.boms_values.push(element);
        }
      });
      this.boms_values.sort(this.sortBy("fk_process_gamme_bom"));
      this.calc_supports_ratios();
      this.boms_change = 0;
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    dechet_change() {
      this.boms_change++;
    },
    calc_supports_ratios() {
      let i = this.templates.findIndex(
        (elm) => elm.id == this.editedItem.fk_template && elm.support == 1
      );
      if (i >= 0) {
        let f = 1;
        let u = 1;
        let j = this.attributs_list.findIndex((elm) => elm.fk_unit_m2 != null);

        if (j >= 0) {
          if (this.attributs_list[j].attribute_value_id) {
            let k = this.values_list.findIndex(
              (elm) => elm.id == this.attributs_list[j].attribute_value_id
            );
            if (k >= 0) f = this.values_list[k].name;
          } else f = this.attributs_list[j].value;

          u = this.unit_convert(
            this.editedItem.fk_unit,
            this.attributs_list[j].fk_unit_m2
          );
        }

        let l = this.attributs_list.findIndex((elm) => elm.laize == 1);
        let v_laize;
        let laize_unit;
        let c = 1;

        if (l >= 0) {
          let v1 = this.attributes.findIndex(
            (elm) => elm.id == this.attributs_list[l].attribute_id
          );
          if (v1 >= 0) laize_unit = this.attributes[v1].fk_unit;
          if (this.attributs_list[l].attribute_value_id) {
            let h = this.values_list.findIndex(
              (elm) => elm.id == this.attributs_list[l].attribute_value_id
            );
            if (h >= 0) v_laize = this.values_list[h].name;
          } else v_laize = this.attributs_list[l].value;

          c = this.unit_convert(8, laize_unit);
        }
        let ratio;

        if (v_laize && c) {
          ratio = parseFloat((u / f / (v_laize / c)).toFixed(2));
          this.boms_change++;
        }
        this.boms_values.forEach((element) => {
          let m = this.templates.findIndex(
            (elm) => elm.id == element.template_id && elm.support == 1
          );
          if (m >= 0) {
            element.ratio = ratio;
            element.fk_unit_ratio = 8;
          }
        });
      }
    },
    unit_convert(fk_unit, fk_unit2) {
      let f = 1;
      let i = this.units.findIndex((elm) => elm.id == fk_unit);
      if (i >= 0) {
        let cat_unit = this.units[i].category_id;

        let j = this.units.findIndex((elm) => elm.id == fk_unit2);
        if (j >= 0) {
          let cat_2 = this.units[j].category_id;
          if (cat_unit == cat_2) {
            let factor_unit_product = this.units[i].factor
              ? this.units[i].factor
              : 0;
            let factor_unit_2 = this.units[j].factor ? this.units[j].factor : 1;

            f = factor_unit_product / factor_unit_2;
          }
        }
      }
      return f;
    },
    faisabilite() {
      this.editedItem.qte = 1;
      this.kfais++;
      this.isFaisabiliteClosed = false;
    },
    bom_add(bom, index) {
      // Shallow copy the bom object
      let e = Object.assign({}, bom);

      // Deep copy the attributes array
      e.attributes = e.attributes.map((element) => Object.assign({}, element));
      e.minus = true;
      // Modify the copied object
      e.ratio = null;
      e.multiple = null;
      e.new = 1;
      e.attributes.forEach((element) => {
        element.value = null;
        element.value_id = null;
      });

      // Insert the modified object into the array
      this.boms_values.splice(index + 1, 0, e);
      this.boms_change++;
    },
    bom_del(index) {
      this.boms_values.splice(index, 1);
    },
    async save_boms() {
      if (this.$refs.form3.validate()) {
        let bom_values = [];
        this.boms_values.forEach((element) => {
          let att = [];

          element.attributes.forEach((elm) => {
            att.push({
              attribute_id: elm.attribute_id,
              value_id: elm.value_id,
              value: elm.value,
            });
          });

          bom_values.push({
            fk_process_gamme_bom: element.fk_process_gamme_bom,
            fk_template: element.template_id,
            attributes: att,
            multiple: element.multiple,
            new: element.new,
            ratio: element.ratio,
            dechet: element.dechet,
          });
        });

        let v = {
          produit_id: this.editedItem.id,

          product: [
            {
              boms: JSON.stringify(bom_values),
              bom_ok: 1,
            },
          ],
        };
        let r = await this.maj(PRODUCT_UPDATE, v);
        if (r) {
          this.editedItem.bom_ok = 1;
          this.editedItem.boms = JSON.stringify(bom_values);
        }
        this.$nextTick(() => {
          this.change = this.minchange;
        });
        this.boms_change = 0;
      }
    },
    att_values(id) {
      let list = this.values_list.filter((elm) => elm.attribute_id == id);
      return list;
    },

    async attribut_save(item) {
      let att = JSON.parse(this.editedItem.attributs_json);
      let i = att.findIndex((elm) => elm.attribute_id == item.attribute_id);
      if (i >= 0) {
        if (item.attribute_value_id) att[i].value = item.attribute_value_id;
        if (item.value) att[i].value = item.value;
        let boms = JSON.parse(this.editedItem.boms);
        if (boms)
          boms.forEach((bom) => {
            bom.attributes.forEach((attribut) => {
              let i = this.attributes.findIndex(
                (elm) =>
                  elm.id == item.attribute_id &&
                  (elm.hyritage == 1 || elm.control == 1)
              );
              if (attribut.attribute_id == item.attribute_id && i >= 0) {
                if (item.attribute_value_id)
                  attribut.value = item.attribute_value_id;
                if (item.value) attribut.value = item.value;
              }
            });
          });
        let v = {
          product: {
            id: this.editedItem.id,
            attributs_json: JSON.stringify(att),
            boms: boms ? JSON.stringify(boms) : null,
          },
        };
        await this.maj(UPDATE_PRODUCT, v);
        this.editedItem.boms = JSON.stringify(boms);
        this.get_boms();
        this.kattribut++;
      }

      if (item.display == "Liste") {
        let i = this.values_list.findIndex(
          (elm) => elm.id == item.attribute_value_id
        );
        if (i >= 0) {
          let j = this.attributs_list.findIndex((elm) => elm.id == item.id);
          if (j >= 0) {
            this.attributs_list[j].value = this.values_list[i].name;
          }
        }
      }
      this.calc_supports_ratios();
      this.$nextTick(() => {
        this.change = this.minchange;
      });
    },
    AttributSelect(item) {
      this.attribut = item;
      this.selitem = item;
    },
    attribut_change(item) {
      this.attribut = item;
      this.selitem = item;
      if (
        (this.prd_type == "03" && this.attribut.incl_sell == "0") ||
        (this.prd_type == "02" && this.attribut.incl_buy == "0") ||
        (this.prd_type == "04" && this.attribut.technique == "1")
      ) {
        this.f_title = this.attribut.name;
        if (this.attribut.display == "Liste") {
          this.attribut_headers[3].type = "select";
          this.attribut_headers[3].select_list = this.values_list.filter(
            (elm) => elm.attribute_id == this.attribut.id
          );
          this.attribut_headers[3].show_list = this.values_list.filter(
            (elm) => elm.attribute_id == this.attribut.id
          );
          this.attribut_headers[3].value = "attribute_value_id";
        } else {
          this.attribut_headers[3].type = "text";
          this.attribut_headers[3].value = "value";
        }
        this.keyform++;
        this.isClosed2 = false;
      }
    },
    closeForm() {
      this.isClosed2 = true;
      this.$nextTick(() => {
        this.change = this.minchange;
      });
    },
    tab_change() {
      if (this.change > this.minchange) {
        this.$nextTick(() => {
          this.tab1 = this.currentTab;
        });
      } else {
        this.currentTab = this.tab1;
      }
    },
    add_price() {
      this.editedItem.prixvente = null;
    },
    refresh_link() {
      this.link_headers[2].whereadd = [
        { column: "ID", operator: "NEQ", value: this.editedItem.id },
        {
          column: "ID",
          operator: "NOT_IN",
          value: this.links_list.map((elm) => elm.link_id),
        },
      ];
    },
    unit_change() {
      if (!this.editedItem.fk_unit_buy) {
        this.editedItem.fk_unit_buy = this.editedItem.fk_unit;
        this.editedItem.factor = 1;
      }
      if (this.units) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        if (i >= 0) {
          this.unit_name = this.units[i].name;
          this.cat_unit = this.units[i].category_id;
          // this.editedItem.rounding = this.units[i].rounding
          //   ? this.units[i].rounding
          //   : 0;

          let j = this.units.findIndex(
            (elm) => elm.id == this.editedItem.fk_unit_buy
          );
          if (j >= 0) this.cat_buy = this.units[j].category_id;
          if (this.editedItem.tobuy == "1" && this.cat_unit == this.cat_buy) {
            let factor_unit_product = this.units[i].factor
              ? this.units[i].factor
              : 0;
            let factor_unit_buy = this.units[j].factor
              ? this.units[j].factor
              : 1;
            if (this.editedItem.id < 0)
              this.editedItem.factor = factor_unit_buy / factor_unit_product;
          }
        }
      }
    },

    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = ["code_client", "nom"];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    async article_statut_change(item, item2) {
      let ok = true;
      if (item2.id == "3") {
        ok = false;

        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Vous-êtes sur le point de rendre l'article' " +
              item.label +
              " obsoléte !! Etês-vous sûr?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        let v = {
          tierprix: {
            id: item.id,
            statut_id: item2.id,
          },
        };
        let r = await this.maj(UPDATE_PRODUCT_ARTICLE, v);
        if (r) {
          item.statuts_name = item2.title;
          item.color = item2.color;
          this.articles_list.splice(item.index, 1, item);
        }
      }
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    lib_change() {
      this.lc++;
    },
    async label_valid() {
      this.lib = true;
      this.ref_code = true;
      this.$refs.form2.resetValidation();
      if (this.editedItem.label || this.editedItem.ref) {
        this.libv = true;
        let w = null;
        if (this.editedItem.id > 0)
          w = {
            AND: [
              {
                OR: [
                  { column: "LABEL", value: this.editedItem.label },
                  {
                    column: "REF",
                    value: this.editedItem.ref ? this.editedItem.ref : null,
                  },
                ],
              },

              { column: "ID", operator: "NEQ", value: this.editedItem.id },
            ],
          };
        else
          w = {
            OR: [
              { column: "LABEL", value: this.editedItem.label },
              {
                column: "REF",
                value: this.editedItem.ref ? this.editedItem.ref : null,
              },
            ],
          };
        let v = {
          where: w,
        };
        let r = await this.requette(ALLCODE, v);
        this.libv = false;
        if (r) {
          if (r.allproducts.length > 0) {
            if (
              r.allproducts.find((elm) => elm.label == this.editedItem.label)
            ) {
              this.lib = false;
              //  ok = false;
            }
            if (
              this.editedItem.ref &&
              r.allproducts.find((elm) => elm.ref == this.editedItem.ref)
            ) {
              this.ref_code = false;
              // ok = false;
            }
          }
        }
      }
      return this.$refs.form2.validate();
    },
    clientChange(item, list) {
      let i = list.findIndex((elm) => elm.id == item);
      if (i >= 0) {
        this.editedItem.sell_net = list[i].sell_net;
      }
      this.editedItem.client_id = item;
      if (this.editedItem.client_id) {
        this.editedItem.standard = 0;
      } else this.editedItem.standard = 1;
    },

    service_change() {
      this.editedItem.fk_product_type = null;
      if (this.editedItem.service == 1) {
        this.editedItem.tva_tx =
          this.$store.state.options[0].default_service_tva;
        this.editedItem.tomanufacture = this.prd_type == "03";
      } else {
        this.editedItem.tva_tx =
          this.$store.state.options[0].default_product_tva;
        if (this.products_types.length == 1)
          this.editedItem.fk_product_type = this.products_types[0].id;
      }
    },

    limiter(e) {
      if (e.length > 6) {
        this.snackbar_text = "Nombre maximum d'attributs atteint";
        this.snackbar_color = "error";
        this.snackbar = true;
        e.pop();
      }
    },

    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async requette(query, v, f) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    OpenQPForm(item) {
      this.pq++;
      item.produit_id = this.editedItem.id;
      this.qpdetail = item;
      this.isQPClosed = false;
    },

    OpenEmailForm() {
      this.isEmailClosed = false;
      this.kem++;
    },
    email_success(item) {
      this.editedItem.bag_send_date = item;

      this.$refs.comments.save_ext_comment("BAG envoyé au client ", 2);

      this.$nextTick(() => {
        this.change = this.minchange;
      });
    },
    closeQPForm() {
      this.pq++;
      this.isQPClosed = true;
    },
    SelectArticleForm(item) {
      this.tierdetail = item;
    },
    OpenArticleForm(item) {
      this.tier_key++;
      item.produit_id = this.editedItem.id;
      this.selected_tiers = this.articles_list;
      this.tierdetail = item;
      this.isTierClosed = false;
    },
    closeFournisForm() {
      this.isTierClosed = true;
      this.tier_key++;
    },

    OpenTTPForm(item) {
      this.ttpdetail = item;
      this.ttpdetail.produit_id = this.editedItem.id;
      this.isTTPClosed = false;
      this.ttp++;
    },
    note_added() {
      this.$nextTick(() => {
        this.change = this.minchange;
      });
    },
    closeTTPForm() {
      this.ttp++;
      this.isTTPClosed = true;
    },
    OpenTPForm(item) {
      this.tpdetail = item;
      this.tpdetail.produit_id = this.editedItem.id;
      this.isTPClosed = false;
      this.tp++;
    },
    closeTPForm() {
      this.tp++;
      this.isTPClosed = true;
    },
    async close() {
      let ok = true;
      if (this.change > this.minchange) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir quitter?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.route.route_name) {
          if (this.item.produit_id) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                produit_id: this.route.produit_id,
                of_id: this.route.of_id,
                filtre: this.route.label,
              },
            });
          }
          if (this.route.cmd) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                cmd: this.route.cmd,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.pfm) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                pfm: this.route.pfm,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.dmd) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                dmd: this.route.dmd,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.tier) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                tier: this.route.tier,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.mvm) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                mvm: this.route.mvm,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.facture) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                facture: this.route.facture,
                item_id: this.route.item_id,
              },
            });
          }
        } else {
          this.$emit("close");
        }
      }
    },
    hist_click() {
      this.drawer = !this.drawer;
      //this.close();
    },
    async validate() {
      if (this.$refs.form2.validate()) {
        let ok = true;

        if (
          this.editedItem.bag_need == 1 &&
          !this.editedItem.bag &&
          this.editedItem.tosell == 1
        ) {
          this.snackbar_text = "BAG manquant - Validation Impossible ";
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          this.$nextTick(() => {
            this.tab2 = "1";
            this.currentTab = this.tab1;
          });
        }
        if (
          ok &&
          this.editedItem.bag_need == 1 &&
          !this.editedItem.bag_confirm &&
          this.editedItem.tosell == 1
        ) {
          this.snackbar_text =
            "Confirmation Client du BAG manquante - Validation Impossible ";
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          this.$nextTick(() => {
            this.tab2 = "1";
            this.currentTab = this.tab1;
          });
        }
        // let i = this.editedItem.attributs.findIndex(
        //   (elm) => elm.value == null || elm.value == ""
        // );
        // if (i >= 0) {
        //   this.snackbar_text =
        //     "Un ou plusieurs attributs manquant - Validation Impossible ";
        //   this.snackbar_color = "error";
        //   this.snackbar = true;
        //   if (this.prd_type == "04")
        //     this.$nextTick(() => {
        //       this.tab1 = "5";
        //       this.tab2 = "1";
        //       this.currentTab = this.tab1;
        //     });

        //   ok = false;
        // }
        // if (ok) {
        //   for (let index = 0; index < this.boms_values.length; index++) {
        //     const element = this.boms_values[index];
        //     if (!element.fk_template) {
        //       ok = false;
        //       break;
        //     } else {
        //       for (let index = 0; index < element.attributes.length; index++) {
        //         const attribut = element.attributes[index];
        //         if (!attribut.value && !attribut.value_id) {
        //           ok = false;
        //           break;
        //         } else if (attribut.values)
        //           if (attribut.values.length > 0) {
        //             for (
        //               let index = 0;
        //               index < attribut.values.length;
        //               index++
        //             ) {
        //               const value = attribut.values[index];
        //               if (!value.ratio) {
        //                 ok = false;
        //                 break;
        //               }
        //             }
        //           }
        //       }
        //       if (ok && !element.multiple && !element.ratio) break;
        //       if (!ok) break;
        //     }
        //   }
        //   if (!ok) {
        //     this.snackbar_text =
        //       "Un ou plusieurs elements de la recette manquant - Validation Impossible ";
        //     this.snackbar_color = "error";
        //     this.snackbar = true;
        //     if (this.prd_type == "04") {
        //       this.$nextTick(() => {
        //         this.tab1 = "5";
        //         this.tab2 = "2";
        //         this.currentTab = this.tab1;
        //       });

        //       if (this.tab1 == "5" && this.tab2 == "2")
        //         if (this.$refs.form3) this.$refs.form3.validate();
        //     }
        //     ok = false;
        //   }
        // }
        if (ok)
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Valider ce produit ! Etês-vous sûr?"
            )
          ) {
            this.editedItem.statut_id = "2";
            let v = {
              product: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
              },
            };
            await this.maj(UPDATE_PRODUCT, v);
            this.$nextTick(() => {
              this.change = this.minchange;
            });
            this.$emit("refresh");
            this.$store.dispatch("Changed", true);
          }
      } else this.tab1 = 1;
    },
    async save() {
      this.tab1 = "1";
      let ok = true;
      if (this.editedItem.statut_id == 4) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Vous-êtes sur le point de rendre ce produit obsoléte !! Etês-vous sûr?"
          )
        ) {
          this.modify = false;
          ok = true;
        }
      }
      this.lib = true;
      if (this.$refs.form2.validate() && ok) {
        let ok = true;
        if (this.editedItem.id < 0 || this.lc > 0)
          ok = await this.label_valid();
        let atelier_id = null;
        if (
          this.editedItem.tomanufacture == "1" &&
          (this.editedItem.id < 0 ||
            (this.editedItem.id > 0 && this.item.tomanufacture == "0"))
        ) {
          atelier_id = 1;
          this.item.tomanufacture = "1";
        }

        if (ok) {
          let cat;
          if (this.cat_change > 0) cat = this.editedItem.categories;
          if (this.editedItem.id > 0) {
            if (
              this.editedItem.tomanufacture == "0" &&
              this.item.tomanufacture == "1"
            ) {
              atelier_id = 0;
              this.item.tomanufacture = "0";
            }

            let v = {
              cat: cat,
              create_uid: this.$store.state.me.id,
              produit_id: this.editedItem.id,
              atelier_id: atelier_id,
              product: [
                {
                  ref: this.editedItem.ref,
                  label: this.editedItem.label,
                  description: this.editedItem.description,
                  label_com: this.editedItem.label_com,
                  note: this.editedItem.note,
                  customcode: this.editedItem.customcode,
                  tosell: this.editedItem.tosell ? 1 : 0,
                  tobuy: this.editedItem.tobuy ? 1 : 0,
                  tomanufacture: this.editedItem.tomanufacture ? 1 : 0,
                  is_moule: this.editedItem.is_moule ? 1 : 0,
                  invest: this.editedItem.invest ? 1 : 0,
                  standard: this.editedItem.standard ? 1 : 0,
                  bylist: this.editedItem.bylist ? 1 : 0,
                  partial: this.editedItem.partial ? 1 : 0,
                  sell_net: this.editedItem.sell_net ? 1 : 0,
                  fifo: this.editedItem.fifo,
                  is_expire: this.editedItem.is_expire ? 1 : 0,
                  expire_delai: this.editedItem.expire_delai,
                  life: this.editedItem.life,
                  statut_id: this.editedItem.statut_id,
                  fk_product_type: this.editedItem.fk_product_type,
                  tva_tx: this.editedItem.tva_tx,
                  prixrevient: this.editedItem.prixrevient,
                  process_id: this.editedItem.process_id,
                  colisattributs: this.editedItem.tabcolisattributs.toString(),
                  maxvalue:
                    this.editedItem.maxvalue > 0
                      ? parseFloat(this.editedItem.maxvalue)
                      : null,
                  minvalue:
                    this.editedItem.minvalue > 0
                      ? parseFloat(this.editedItem.minvalue)
                      : null,
                  barcode: this.editedItem.barcode,
                  fk_barcode_type: this.editedItem.fk_barcode_type,
                  fk_unit: this.editedItem.fk_unit,
                  fk_unit_buy: this.editedItem.fk_unit_buy,
                  fk_unit_colis: this.editedItem.fk_unit_colis,
                  factor: parseFloat(this.editedItem.factor),
                  // rounding: this.editedItem.rounding
                  //   ? parseInt(this.editedItem.rounding)
                  //   : 2,
                  url: this.editedItem.url,
                  code_cmpt: this.editedItem.code_cmpt,
                  code: this.editedItem.code,
                  service: this.editedItem.service,
                  client_id: this.editedItem.client_id,
                  fk_template: this.editedItem.fk_template,
                  decimal: parseInt(this.editedItem.decimal),
                  durration: this.editedItem.durration,
                  depot_id: this.editedItem.depot_id,
                  prixvente: parseFloat(this.editedItem.prixvente),
                  seuil_stock_alerte: parseFloat(
                    this.editedItem.seuil_stock_alerte
                  ),
                  spec_tech: this.editedItem.spec_tech,
                  preview_file: this.editedItem.preview_file,
                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            await this.maj(PRODUCT_UPDATE, v);
            this.$store.dispatch("Changed", true);
            this.$nextTick(() => {
              this.change = this.minchange;
            });
            this.cat_change = 0;
          } else {
            let pfm_det_id;
            if (this.editedItem.bag_need == 1 && this.editedItem.tosell == 1)
              this.editedItem.statut_id = "11";
            else this.editedItem.statut_id = "1";
            if (this.route.pfm) {
              pfm_det_id = this.route.item_id;
            }
            let v = {
              cat: cat,
              create_uid: this.$store.state.me.id,
              atelier_id: atelier_id,
              pfm_det_id: pfm_det_id,
              product: [
                {
                  ref: this.editedItem.ref,
                  label: this.editedItem.label,
                  description: this.editedItem.description,
                  label_com: this.editedItem.label_com,
                  note: this.editedItem.note,
                  customcode: this.editedItem.customcode,
                  tosell: this.editedItem.tosell ? 1 : 0,
                  tobuy: this.editedItem.tobuy ? 1 : 0,
                  tomanufacture: this.editedItem.tomanufacture ? 1 : 0,
                  is_moule: this.editedItem.is_moule ? 1 : 0,
                  invest: this.editedItem.invest ? 1 : 0,
                  standard: this.editedItem.standard ? 1 : 0,
                  bylist: this.editedItem.bylist ? 1 : 0,
                  partial: this.editedItem.partial ? 1 : 0,
                  sell_net: this.editedItem.sell_net ? 1 : 0,
                  fifo: this.editedItem.fifo,
                  is_expire: this.editedItem.is_expire ? 1 : 0,
                  expire_delai: this.editedItem.expire_delai,
                  life: this.editedItem.life,
                  statut_id: this.editedItem.statut_id,
                  fk_product_type: this.editedItem.fk_product_type,
                  tva_tx: this.editedItem.tva_tx,
                  prixrevient: this.editedItem.prixrevient,
                  process_id: this.editedItem.process_id,
                  colisattributs: this.editedItem.tabcolisattributs.toString(),
                  maxvalue:
                    this.editedItem.maxvalue > 0
                      ? parseFloat(this.editedItem.maxvalue)
                      : null,
                  minvalue:
                    this.editedItem.minvalue > 0
                      ? parseFloat(this.editedItem.minvalue)
                      : null,
                  barcode: this.editedItem.barcode,
                  fk_barcode_type: this.editedItem.fk_barcode_type,
                  fk_unit: this.editedItem.fk_unit,
                  fk_unit_buy: this.editedItem.fk_unit_buy,
                  fk_unit_colis: this.editedItem.fk_unit_colis,
                  factor: parseFloat(this.editedItem.factor),
                  // rounding: this.editedItem.rounding
                  //   ? parseInt(this.editedItem.rounding)
                  //   : 2,
                  url: this.editedItem.url,
                  code_cmpt: this.editedItem.code_cmpt,
                  code: this.editedItem.code,
                  service: this.editedItem.service,
                  client_id: this.editedItem.client_id,
                  fk_template: this.editedItem.fk_template,
                  decimal: parseInt(this.editedItem.decimal),
                  durration: this.editedItem.durration,
                  depot_id: this.editedItem.depot_id,
                  prixvente: parseFloat(this.editedItem.prixvente),
                  seuil_stock_alerte: parseFloat(
                    this.editedItem.seuil_stock_alerte
                  ),
                  spec_tech: this.editedItem.spec_tech,
                  preview_file: this.editedItem.preview_file,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              ],
            };
            let r2 = await this.maj(INSERT_PRODUCT, v);

            if (r2) {
              this.editedItem = r2.InsertProduct;
              this.$nextTick(() => {
                this.change = this.minchange;
                this.cat_change = 0;
              });

              this.link_headers[5].default = this.editedItem.id;
              this.$store.dispatch("Changed", true);
              this.$emit("refresh");
            }
          }
          if (this.route.route_name) {
            if (this.item.produit_id) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  produit_id: this.route.produit_id,
                  of_id: this.route.of_id,
                  filtre: this.route.label,
                },
              });
            }
            if (this.route.cmd) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  cmd: this.route.cmd,
                  item_id: this.route.item_id,
                  produit_id: this.editedItem.id,
                },
              });
            }
            if (this.route.pfm) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  pfm: this.route.pfm,
                  item_id: this.route.item_id,
                  produit_id: this.editedItem.id,
                },
              });
            }
            if (this.route.dmd) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  dmd: this.route.dmd,
                  item_id: this.route.item_id,
                  produit_id: this.editedItem.id,
                },
              });
            }
            if (this.route.mvm) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  mvm: this.route.mvm,
                  item_id: this.route.item_id,
                  produit_id: this.editedItem.id,
                },
              });
            }
            if (this.route.facture) {
              this.$router.push({
                name: this.route.route_name,
                params: {
                  facture: this.route.facture,
                  item_id: this.route.item_id,
                  produit_id: this.editedItem.id,
                },
              });
            }
          }
        }
      } else this.tab1 = 1;
    },
  },
};
</script>
<style>
.centered-input input {
  text-align: center;
}
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
</style>
